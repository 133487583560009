import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import GenderSelector from "../../applicationUi/components/GenderSelector";
import PronounSelector from "../../applicationUi/components/PronounSelector";
import GoogleMapsAutocomplete from "../../applicationUi/components/GoogleAutoComplete";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateClient } from "../../store/thunks/clientsThunk";
import { logError, logInfo } from "../../utils/logger"; // Updated to match available exports
import { toast } from "react-toastify";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";
import CircularButton from "../../applicationUi/components/CircularButton";

export default function ClientDetails() {
  const dispatch = useDispatch();
  const [treatingClinician, setTreatingClinician] = useState(null);
  const [supervisingClinician, setSupervisingClinician] = useState(null);
  const { selectedTreatingClinician, selectedSupervisingClinician } =
    useSelector((state) => state.clinicians);
  const client = useSelector((state) => state.clients.selectedClient);

  const [formData, setFormData] = useState({
    clientId: client?.clientId ?? "",
    firstName: client?.firstName ?? "",
    lastName: client?.lastName ?? "",
    dateOfBirth: client?.dateOfBirth
      ? new Date(client.dateOfBirth).toISOString().split("T")[0]
      : "", // Store as YYYY-MM-DD format
    email: client.email,
    additionalEmails: client?.additionalEmails ?? [{ label: "", email: "" }], // Updated to include additionalEmails
    healthCardNumber: client?.healthCardNumber ?? "",
    gender: client?.gender ?? "",
    pronouns: client?.pronouns ?? "",
    QBOClientId: client?.QBOClientId ?? "",
    address: {
      aptUnit: client?.address?.aptUnit ?? "",
      streetNumber: client?.address?.streetNumber ?? "",
      streetName: client?.address?.streetName ?? "",
      city: client?.address?.city ?? "",
      province: client?.address?.province ?? "",
      postalCode: client?.address?.postalCode ?? "",
      country: client?.address?.country ?? "Canada",
    },
    phoneNumbers: client?.phoneNumbers ?? [{ type: "", number: "" }],
    treatingClinician: client?.treatingClinician ?? {
      current: null,
      history: [],
    },
    supervisingClinician: client?.supervisingClinician ?? {
      current: null,
      history: [],
    },
  });

  // Set the clinicians properly
  useEffect(() => {
    if (client) {
      setTreatingClinician(client.treatingClinician?.current);
      setSupervisingClinician(client.supervisingClinician?.current);
    }
  }, [client]);

  // State to store the original data for reset on Cancel
  const [originalData, setOriginalData] = useState({ ...client });
  const [isEditing, setIsEditing] = useState(false);

  console.log(client);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    logInfo("Client detail updated", {
      componentName: "ClientDetails",
      action: "updateField",
      field: name,
      value: value,
    });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value,
      },
    }));
    logInfo("Client address updated", {
      componentName: "ClientDetails",
      action: "updateAddress",
      field: name,
      value: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
    logInfo("Client date of birth updated", {
      componentName: "ClientDetails",
      action: "updateDateOfBirth",
      date: date,
    });
  };

  const handleCancel = () => {
    setFormData({ ...originalData });
    setIsEditing(!isEditing);
    logInfo("Edit cancelled", {
      componentName: "ClientDetails",
      action: "cancelEdit",
    });
  };

  const handleAdditionalEmailChange = (index, field, value) => {
    const updatedEmails = formData.additionalEmails.map((emailObj, i) =>
      i === index ? { ...emailObj, [field]: value } : emailObj
    );
    setFormData((prevData) => ({
      ...prevData,
      additionalEmails: updatedEmails,
    }));
  };

  const handleAddAdditionalEmail = () => {
    setFormData((prevData) => ({
      ...prevData,
      additionalEmails: [
        ...prevData.additionalEmails,
        { label: "", email: "" },
      ],
    }));
  };

  const handleRemoveAdditionalEmail = (index) => {
    const updatedEmails = formData.additionalEmails.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      additionalEmails: updatedEmails,
    }));
  };

  const handleRemovePhone = (index) => {
    const updatedPhones = formData.phoneNumbers.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      phoneNumbers: updatedPhones,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const clientData = {
      clientId: formData.clientId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      type: client.type,
      address: {
        aptUnit: formData.address.aptUnit,
        streetNumber: formData.address.streetNumber,
        streetName: formData.address.streetName,
        city: formData.address.city,
        province: formData.address.province,
        postalCode: formData.address.postalCode,
      },
      email: formData.email,
      additionalEmails: formData.additionalEmails,
      healthCardNumber: formData.healthCardNumber,
      gender: formData.gender,
      pronouns: formData.pronouns,
      phoneNumbers: formData.phoneNumbers,
      treatingClinician: {
        current: treatingClinician?._id || formData.treatingClinician.current,
        history: [
          ...(formData.treatingClinician.history || []),
          ...(treatingClinician?._id &&
          treatingClinician?._id !== formData.treatingClinician.current
            ? [{ clinician: treatingClinician._id, date: new Date() }]
            : []),
        ],
      },
      supervisingClinician: {
        current:
          supervisingClinician?._id || formData.supervisingClinician.current,
        history: [
          ...(formData.supervisingClinician.history || []),
          ...(supervisingClinician?._id &&
          supervisingClinician?._id !== formData.supervisingClinician.current
            ? [{ clinician: supervisingClinician._id, date: new Date() }]
            : []),
        ],
      },
    };

    try {
      await dispatch(updateClient({ id: client._id, clientData }));
      setIsEditing(false);
      toast.success("Client details saved successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      logInfo("Client details saved", {
        componentName: "ClientDetails",
        action: "saveClientDetails",
        clientId: client._id,
      });
    } catch (error) {
      alert("Failed to save client details. Please try again.");
      logError("Failed to save client details", {
        componentName: "ClientDetails",
        action: "saveClientDetails",
        error: error.message,
        clientId: client._id,
      });
    }
  };

  const handlePhoneChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPhones = formData.phoneNumbers.map((phone, i) =>
      i === index ? { ...phone, [name]: value } : phone
    );
    setFormData((prevData) => ({
      ...prevData,
      phoneNumbers: updatedPhones,
    }));
    logInfo("Client phone number updated", {
      componentName: "ClientDetails",
      action: "updatePhoneNumber",
      index: index,
      field: name,
      value: value,
    });
  };

  const handleEmailChange = (index, e) => {
    const { value } = e.target;
    const updatedEmail = [...formData.email];
    updatedEmail[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      emails: updatedEmail,
    }));
    logInfo("Client email updated", {
      componentName: "ClientDetails",
      action: "updateEmail",
      index: index,
      value: value,
    });
  };

  const handleAddPhone = () => {
    setFormData((prevData) => ({
      ...prevData,
      phoneNumbers: [...prevData.phoneNumbers, { type: "", number: "" }],
    }));
    logInfo("Phone number field added", {
      componentName: "ClientDetails",
      action: "addPhoneField",
    });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    logInfo("Edit mode toggled", {
      componentName: "ClientDetails",
      action: "toggleEdit",
      isEditing: !isEditing,
    });
  };

  // Function to handle the selected place from Google Maps Autocomplete
  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;

    // Helper function to get the component by type
    const getComponent = (type) =>
      addressComponents.find((component) => component.types.includes(type))
        ?.long_name;

    // Update formData with the place details
    setFormData((prevData) => ({
      ...prevData,
      address: {
        aptUnit: getComponent("subpremise") || prevData.address.aptUnit,
        streetNumber:
          getComponent("street_number") || prevData.address.streetNumber,
        streetName: getComponent("route") || prevData.address.streetName,
        city: getComponent("locality") || prevData.address.city,
        province:
          getComponent("administrative_area_level_1") ||
          prevData.address.province,
        postalCode: getComponent("postal_code") || prevData.address.postalCode,
        country: getComponent("country") || prevData.address.country,
      },
    }));
    logInfo("Address autocompleted", {
      componentName: "ClientDetails",
      action: "autocompleteAddress",
      placeId: place.place_id,
    });
  };

  return (
    <div className="space-y-6">
      {!isEditing && (
        <button
          type="button"
          onClick={handleEditClick}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
          Edit
        </button>
      )}

      {isEditing && (
        <>
          <button
            type="button"
            onClick={handleSave}
            className="inline-flex items-center mr-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
            Save
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
            Cancel
          </button>
        </>
      )}

      <div className="space-y-4">
        {/* Client ID and Clinicians */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="text-sm font-medium text-gray-700">
              Client ID
            </label>
            <input
              type="text"
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Treating Clinician
            </label>
            {isEditing ? (
              <>
                <SearchDropdown
                  prefix=":user:"
                  displayFields={["firstName", "lastName", "email"]}
                  onSelection={(user) => {
                    setTreatingClinician(user);
                    logInfo("Treating clinician selected", {
                      componentName: "ClientDetails",
                      action: "TreatingClinicianSelected",
                      clinicianId: user._id,
                    });
                  }}
                  placeholder={
                    selectedTreatingClinician
                      ? `${selectedTreatingClinician.firstName} ${selectedTreatingClinician.lastName}`
                      : "Search for a treating clinician"
                  }
                />
                {treatingClinician && (
                  <div className="mt-2 text-sm text-gray-700">
                    Selected:{" "}
                    <span className="font-medium text-gray-900">
                      {treatingClinician.firstName} {treatingClinician.lastName}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <input
                type="text"
                name="treatingClinician"
                value={
                  selectedTreatingClinician
                    ? `${selectedTreatingClinician.firstName} ${selectedTreatingClinician.lastName}`
                    : "No treating clinician assigned"
                }
                onChange={handleInputChange}
                readOnly
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            )}
          </div>

          <div>
            <label className="text-sm font-medium text-gray-700">
              Supervising Clinician
            </label>
            {isEditing ? (
              <>
                <SearchDropdown
                  prefix=":user:"
                  displayFields={["firstName", "lastName", "email"]}
                  onSelection={(user) => {
                    setSupervisingClinician(user);
                    logInfo("Supervising clinician selected", {
                      componentName: "ClientDetails",
                      action: "Supervising Clinician Selected",
                      clinicianId: user._id,
                    });
                  }}
                  placeholder={
                    selectedSupervisingClinician
                      ? `${selectedSupervisingClinician.firstName} ${selectedSupervisingClinician.lastName}`
                      : "Search for a supervising clinician"
                  }
                />
                {supervisingClinician && (
                  <div className="mt-2 text-sm text-gray-700">
                    Selected:{" "}
                    <span className="font-medium text-gray-900">
                      {supervisingClinician.firstName}{" "}
                      {supervisingClinician.lastName}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <input
                type="text"
                name="supervisingClinician"
                value={
                  selectedSupervisingClinician
                    ? `${selectedSupervisingClinician.firstName} ${selectedSupervisingClinician.lastName}`
                    : "No supervising clinician assigned"
                }
                onChange={handleInputChange}
                readOnly
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            )}
          </div>
        </div>

        {/* Full Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
        </div>

        {/* Date of Birth, Email, Health Card */}
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Primary Email
            </label>
            <div className="flex items-center mb-2">
              <input
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly={!isEditing}
                placeholder={isEditing ? "New Email" : ""}
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
              />
            </div>
          </div>

          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Health Card #
            </label>
            <input
              type="text"
              name="healthCardNumber"
              value={formData.healthCardNumber}
              onChange={handleInputChange}
              readOnly={!isEditing}
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              DOB (dd/mm/yyyy)
            </label>
            <div className="mt-1">
              <DatePicker
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm px-3 py-2"
                selected={formData.dateOfBirth}
                onChange={handleDateChange}
                readOnly={!isEditing}
                disabled={!isEditing}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>

        {/* Gender, QBOClientId */}
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Gender
            </label>
            <div className="mt-1">
              <GenderSelector
                value={formData.gender}
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    gender: e.target.value,
                  }));
                  logInfo("Gender updated", {
                    componentName: "ClientDetails",
                    action: "updateGender",
                    gender: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Pronouns
            </label>
            <div className="mt-1">
              <PronounSelector
                value={formData.pronouns}
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    pronouns: e.target.value,
                  }));
                  logInfo("Pronouns updated", {
                    componentName: "ClientDetails",
                    action: "updatePronouns",
                    pronouns: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div>
            <label className="text-sm font-medium text-gray-700">
              QBO Client ID
            </label>
            <input
              type="text"
              name="QBOClientId"
              value={formData.QBOClientId}
              onChange={handleInputChange}
              readOnly
              className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>
        </div>

        {/* Address */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">Address</label>
          {isEditing && (
            <div className="space-y-2">
              <GoogleMapsAutocomplete
                onPlaceSelected={handlePlaceSelected}
                inputId="autocomplete-address"
              />
            </div>
          )}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                name="aptUnit"
                value={formData.address.aptUnit}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Apt/Unit"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="streetNumber"
                value={formData.address.streetNumber}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Street Number"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="streetName"
                value={formData.address.streetName}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Street Name"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="city"
                value={formData.address.city}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="City"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="province"
                value={formData.address.province}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Province"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="postalCode"
                value={formData.address.postalCode}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Postal Code"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
            <div>
              <input
                type="text"
                name="country"
                value={formData.address.country}
                onChange={handleAddressChange}
                readOnly={!isEditing}
                placeholder="Country"
                className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
              />
            </div>
          </div>
        </div>

        {/* Emails Section */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">
            Additional Emails
          </label>
          {formData.additionalEmails.length > 0 ? (
            formData.additionalEmails.map((email, index) => (
              <div key={index} className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="type"
                  value={email.label}
                  onChange={(e) =>
                    handleAdditionalEmailChange(index, "type", e.target.value)
                  }
                  readOnly={!isEditing}
                  placeholder="Email Type"
                  className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
                <div className="flex items-center">
                  <input
                    type="email"
                    name="email"
                    value={email.email}
                    onChange={(e) =>
                      handleAdditionalEmailChange(
                        index,
                        "email",
                        e.target.value
                      )
                    }
                    readOnly={!isEditing}
                    placeholder="Email Address"
                    className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  />
                  {isEditing && (
                    <CircularButton
                      onClick={() => handleRemoveAdditionalEmail(index)}
                      icon={<TrashIcon className="h-5 w-5" />}
                      label="Remove"
                      className="ml-2 m-2"
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500">No additional emails added.</p>
          )}
          {isEditing && (
            <CircularButton
              onClick={handleAddAdditionalEmail}
              icon={<PlusIcon className="h-5 w-5" />}
              label="Add Email"
              className="mt-2 m-2"
            />
          )}
        </div>

        {/* Phone Numbers */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700">
            Phone Numbers
          </label>
          {formData.phoneNumbers.length > 0 ? (
            formData.phoneNumbers.map((phone, index) => (
              <div key={index} className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="type"
                  value={phone.type}
                  onChange={(e) => handlePhoneChange(index, e)}
                  readOnly={!isEditing}
                  placeholder="Phone Type"
                  className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
                <div className="flex items-center">
                  <input
                    type="text"
                    name="number"
                    value={phone.number}
                    onChange={(e) => handlePhoneChange(index, e)}
                    readOnly={!isEditing}
                    placeholder="New Phone Number"
                    className="w-full border border-gray-300 rounded-md shadow-sm sm:text-sm"
                  />
                  {isEditing && (
                    <>
                      <CircularButton
                        onClick={() => handleRemovePhone(index)}
                        icon={<TrashIcon className="h-5 w-5" />}
                        label="Remove"
                        className="ml-2 my-1 mx-2 m-2"
                      />
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500">No phone numbers added.</p>
          )}
          {isEditing && (
            <CircularButton
              onClick={handleAddPhone}
              icon={<PlusIcon className="h-5 w-5" />}
              label="Add Phone"
              className="mt-2 m-2"
            />
          )}
        </div>
      </div>
    </div>
  );
}
