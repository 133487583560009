import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointmentById } from "../../store/thunks/appointmentsThunk";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import InvoicePaymentModal from "../../modals/InvoicePaymentModal"; // Import the modal
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/your_logo.png";

const InvoiceGenerator = ({
  initialViewMode = false,
  onEditInvoice,
  onSplitInvoice,
}) => {
  const dispatch = useDispatch();

  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );
  const [viewMode, setViewMode] = useState(initialViewMode);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const invoiceData = useSelector((state) => state.invoices.currentInvoice);
  const paymentData = useSelector((state) => state.payments.currentPayment);
  const clinic = useSelector((state) => state.clinics.selectedClinic);

  useEffect(() => {
    if (invoiceData?.appointmentId) {
      // Dispatch fetchAppointmentById and set selected appointment
      dispatch(fetchAppointmentById(invoiceData.appointmentId))
        .unwrap()
        .then((appointment) => {
          dispatch(setSelectedAppointment(appointment));
        })
        .catch((error) => {
          console.error(
            `Failed to fetch appointment with ID ${invoiceData.appointmentId}:`,
            error
          );
        });
    }
  }, [dispatch, invoiceData]);

  console.log("invoice generator opened");
  console.log("Invoice data", invoiceData);
  console.log("Payment data", paymentData);
  console.log("Selected appointment (fetched)", selectedAppointment);

  return (
    <div className="border border-gray-200 rounded-md px-4 py-4 flex flex-col gap-4">
      {/* Status Information */}
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-sm font-medium text-gray-700">
              Current Status:{" "}
              {invoiceData
                ? invoiceData.status === "created"
                  ? "Invoiced"
                  : `${invoiceData.status} - ${invoiceData.invoiceId}`
                : "No Invoice Data"}
            </h3>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={() => setIsPaymentModalOpen(true)}
          className="bg-green-600 text-sm font-semibold text-white px-4 py-2 rounded-md">
          Collect Payment
        </button>
        <button
          onClick={() => {
            /* Add email to client logic here */
          }}
          className="bg-blue-600 text-sm font-semibold text-white px-4 py-2 rounded-md">
          Email to Client
        </button>
        <button
          onClick={() => onEditInvoice(invoiceData)}
          className="bg-gray-600 text-sm font-semibold text-white px-4 py-2 rounded-md">
          Edit Invoice
        </button>
        <button
          onClick={() => onSplitInvoice(invoiceData)}
          className="bg-gray-600 text-sm font-semibold text-white px-4 py-2 rounded-md">
          Convert to Split
        </button>
        {/* <ConvertInvoice invoice={data} /> */}
      </div>

      {/* PDF Button and Viewer */}
      <div className="flex flex-col mt-4">
        <PDFViewer
          style={{
            width: "100%",
            height: "500px",
            marginBottom: "10px",
            marginTop: "10px",
          }}>
          <MyDocument
            selectedAppointment={selectedAppointment}
            invoiceData={invoiceData}
            paymentData={paymentData}
          />
        </PDFViewer>
      </div>

      {/* Invoice Payment Modal */}
      {isPaymentModalOpen && (
        <InvoicePaymentModal
          // invoice={invoiceData}
          onClose={async () => {
            setIsPaymentModalOpen(false); // Close the modal
          }}
        />
      )}
    </div>
  );
};

export default InvoiceGenerator;

const MyDocument = ({ selectedAppointment, invoiceData, paymentData }) => {
  console.log("My document selectedAppointment", selectedAppointment);
  console.log("My document invoiceData", invoiceData);
  console.log("My document paymentData", paymentData);
  const clinic = selectedAppointment?.clinic || {};
  const clinician = selectedAppointment?.clinician || {};
  const client = selectedAppointment?.client || {};
  const services = invoiceData?.services || [];
  const address = selectedAppointment?.client?.address || {};
  const parentInfo = selectedAppointment?.splitBilling?.parentInfo || {};
  const paymentInfo = paymentData || {};

  console.log("Payment Info", paymentInfo);

  const calculateSubtotal = (services) => {
    return services.reduce(
      (acc, service) => acc + service.cost * service.quantity,
      0
    );
  };

  const calculateTax = (services) => {
    return services.reduce((acc, service) => {
      if (service.isTaxable && service.taxRate && service.taxRate > 0) {
        return acc + service.cost * service.quantity * service.taxRate;
      }
      return acc;
    }, 0);
  };

  const subtotal = calculateSubtotal(services);
  const tax = calculateTax(services);
  const total = subtotal + tax;

  const totalPayments = paymentInfo?.amount || 0;
  const balanceDue = (total || 0) - totalPayments;

  const formattedDate = new Date(invoiceData.updatedAt).toLocaleDateString(
    "en-US",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );

  const formattedTime = new Date(invoiceData.updatedAt).toLocaleTimeString(
    "en-US",
    {
      timeStyle: "long",
    }
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header with logo */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <View style={{ alignItems: "flex-end" }}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              {clinic.name}
            </Text>
            <Text style={styles.text}>{clinic.address?.street || ""}</Text>
            <Text style={styles.text}>
              {`${clinic.address?.city || ""}, ${
                clinic.address?.province || ""
              }, ${clinic.address?.postalCode || ""}`}
            </Text>
          </View>
        </View>

        {/* Clinician and Invoice info */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            marginVertical: 10,
            paddingBottom: 20,
          }}>
          <View style={{ flexBasis: "70%" }}>
            <Text style={styles.text}>
              {clinician.firstName} {clinician.lastName}
            </Text>
            <Text style={styles.text}>{clinician.invoiceDescriptor}</Text>
          </View>

          <View style={{ flexBasis: "30%", alignItems: "flex-end" }}>
            <Text style={styles.text}>Invoice: {invoiceData.invoiceId}</Text>
            <Text style={styles.text}>
              Service Date:{" "}
              {new Date(invoiceData.serviceDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </View>
        </View>

        {/* Client and Bill To */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}>
          {/* Client */}
          <View
            style={{
              marginBottom: 20,
              borderWidth: 1,
              flex: 1,
              marginRight: 10,
            }}>
            <View
              style={{
                borderBottom: 1,
                paddingHorizontal: 10,
                paddingVertical: 6,
              }}>
              <Text style={styles.text}>Client</Text>
            </View>
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 10,
              }}>
              <Text style={styles.text}>{`${(client.firstName || "").trim()} ${(
                client.lastName || ""
              ).trim()}`}</Text>
              <Text style={styles.text}>
                {`${address.streetNumber || ""} ${address.streetName || ""}`}
              </Text>
              <Text style={styles.text}>{`${address.city || ""}, ${
                address.province || ""
              }, ${address.postalCode || ""}`}</Text>
            </View>
          </View>

          {/* Bill To */}
          <View
            style={{
              marginBottom: 20,
              borderWidth: 1,
              flex: 1,
              marginLeft: 10,
            }}>
            <View
              style={{
                borderBottom: 1,
                paddingHorizontal: 10,
                paddingVertical: 6,
              }}>
              <Text style={styles.text}>Bill To</Text>
            </View>
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 10,
              }}>
              <Text style={styles.text}>{`${(parentInfo._id
                ? parentInfo.firstName
                : client.firstName || ""
              ).trim()} ${(parentInfo._id
                ? parentInfo.lastName
                : client.lastName || ""
              ).trim()}`}</Text>
              <Text style={styles.text}>
                {`${
                  parentInfo._id
                    ? parentInfo.address?.streetNumber || ""
                    : client.address?.streetNumber || ""
                } ${
                  parentInfo._id
                    ? parentInfo.address?.streetName || ""
                    : client.address?.streetName || ""
                }`}
              </Text>
              <Text style={styles.text}>{`${
                parentInfo._id
                  ? parentInfo.address?.city || ""
                  : client.address?.city || ""
              }, ${
                parentInfo._id
                  ? parentInfo.address?.province || ""
                  : client.address?.province || ""
              }, ${
                parentInfo._id
                  ? parentInfo.address?.postalCode || ""
                  : client.address?.postalCode || ""
              }`}</Text>
            </View>
          </View>
        </View>

        {/* Charges Table */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader, width: "50%" }}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "5%" }}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "30%" }}>
              <Text style={styles.tableCell}>Notes</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "15%" }}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
          </View>
          {/* Services Rows */}
          {services.map((service, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "50%" }}>
                <Text style={styles.tableCell}>{service.description}</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "5%",
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>{service.quantity}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%" }}>
                <Text style={styles.tableCell}>{service.notes}</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "15%",
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>
                  ${(service.cost * service.quantity)?.toFixed(2)}
                </Text>
              </View>
            </View>
          ))}

          {/* Subtotal Row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Subtotal</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${subtotal?.toFixed(2)}</Text>
            </View>
          </View>

          {/* Taxes Row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Tax</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${tax?.toFixed(2)}</Text>
            </View>
          </View>

          {/* Total Row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${total?.toFixed(2)}</Text>
            </View>
          </View>

          {/* Payments Section */}
          {paymentInfo && (
            <View style={styles.tableRow}>
              <View
                style={{
                  ...styles.tableCol,
                  width: "85%",
                  borderRightWidth: 1,
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>
                  {/* Convert payment method to its English description */}
                  {`${
                    paymentInfo.paymentMethod
                      ? clinic.paymentMethods.find(
                          (method) => method.value === paymentInfo.paymentMethod
                        )?.englishDescription || paymentInfo.paymentMethod
                      : ""
                  }${
                    paymentInfo.paymentDate
                      ? ` - ${new Date(
                          paymentInfo.paymentDate
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}`
                      : ""
                  }`}
                </Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "15%",
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>
                  -${(paymentInfo.amount || 0).toFixed(2)}
                </Text>
              </View>
            </View>
          )}

          {/* Balance Due Row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Balance Due</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${balanceDue?.toFixed(2)}</Text>
            </View>
          </View>
        </View>
        {/* Notes Section */}
        <View
          style={{
            marginTop: 20,
          }}>
          <Text style={styles.text}>Notes</Text>
          <View
            style={{
              marginTop: 5,
              padding: 5,
              borderWidth: 1,
              width: "100%",
              minHeight: 60,
            }}>
            <Text style={styles.text}>{invoiceData.notes}</Text>
          </View>
        </View>

        {/* Signature */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 50,
          }}>
          {" "}
          <Text style={styles.text}>
            Signature: ________________________________
          </Text>
          <Text style={styles.text}>
            Date: ________________________________
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Text style={{ fontSize: 8 }}>
            [Version {invoiceData.__v + 1}. Last updated on {formattedDate}{" "}
            {formattedTime}]
          </Text>
        </View>
      </Page>
    </Document>
  );
};

// Styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    maxWidth: 180,
    maxHeight: 100,
    width: "auto",
    height: "auto",
  },

  borderSection: {
    marginBottom: 20,
    borderWidth: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#D3D3D3",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});
