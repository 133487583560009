import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import { fetchAppointmentsByDateRangeOnly } from "../../store/thunks/appointmentsThunk";
import { fetchMeetingsForCurrentWeek } from "../../store/thunks/meetingsThunk";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setSelectedMeeting } from "../../store/slices/meetingSlice";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";

import { format, addDays, startOfWeek, parseISO, addMinutes } from "date-fns";
import { toDate } from "date-fns-tz";
// import { MultiSelect } from "your-multiselect-component-path";
import MultiSelect from "../../applicationUi/components/MultiSelect";

export default function SevenDayCalendarV2() {
  const dispatch = useDispatch();
  const sevenDayAppointments = useSelector(
    (state) => state.appointments.sevenDayAppointments
  );
  const sevenDayMeetings = useSelector((state) => state.meetings.meetings);
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);
  const user = useSelector((state) => state.auth.user);

  console.log("7dayappts", sevenDayAppointments);
  console.log("sevenDayMeetings", sevenDayMeetings);

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [showWorkingHoursOnly, setShowWorkingHoursOnly] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  console.log("hovereditem", hoveredItem);
  // Get start of current week (Sunday)
  const startDate = startOfWeek(currentDate, { weekStartsOn: 0 });

  // Generate week days
  const weekDays = Array.from({ length: 7 }, (_, index) => {
    const date = addDays(startDate, index);
    const isToday =
      format(date, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd");

    return {
      shortDay: format(date, "E"), // Mon, Tue, etc.
      fullDay: format(date, "EEE"), // Monday, Tuesday, etc.
      date: format(date, "d"), // 1-31
      isToday,
    };
  });

  // Generate time slots
  const generateTimeSlots = () => {
    const startHour = showWorkingHoursOnly ? 7 : 0;
    const endHour = showWorkingHoursOnly ? 19 : 24;

    return Array.from({ length: endHour - startHour }, (_, index) => {
      const hour = startHour + index;
      return {
        hour,
        label: format(new Date().setHours(hour, 0), "ha").toLowerCase(),
      };
    });
  };

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  const handlePreviousWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, -7));
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 7));
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const getCurrentWeek = (date) => {
    const week = [];
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      week.push(day);
    }
    return week;
  };

  useEffect(() => {
    const currentWeek = getCurrentWeek(currentDate);
    const startDate = currentWeek[0];
    const endDate = currentWeek[6];
    dispatch(fetchAppointmentsByDateRangeOnly({ startDate, endDate }));
    dispatch(fetchMeetingsForCurrentWeek({ startDate, endDate }));
    dispatch(fetchClinicians());
  }, [dispatch, currentDate]);

  const calculateAppointmentGridPosition = (startTime, endTime) => {
    // Convert UTC to local time
    const localStart = toDate(parseISO(startTime), {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const localEnd = toDate(parseISO(endTime), {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Calculate grid row (each hour = 12 rows, each row = 5 minutes)
    const startHour = localStart.getHours();
    const startMinutes = localStart.getMinutes();
    const startRow = startHour * 12 + Math.floor(startMinutes / 5) + 1;

    // Calculate duration in 5-minute increments
    const durationMinutes = (localEnd - localStart) / (1000 * 60);
    const span = Math.ceil(durationMinutes / 5);

    return { startRow, span };
  };

  const calculateMeetingGridPosition = (date, startTime, duration) => {
    // Combine date and time
    const fullDateTime = parseISO(date.split("T")[0] + "T" + startTime);

    // Convert to local time
    const localStart = toDate(fullDateTime, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Calculate end time by adding duration minutes
    const localEnd = new Date(localStart.getTime() + duration * 60000);

    // Calculate grid row (each hour = 12 rows, each row = 5 minutes)
    const startHour = localStart.getHours();
    const startMinutes = localStart.getMinutes();
    const startRow = startHour * 12 + Math.floor(startMinutes / 5) + 1;

    // Calculate span based on duration (in 5-minute increments)
    const span = Math.ceil(duration / 5);

    return { startRow, span };
  };

  // Add this helper function to find overlapping events
  const findOverlappingEvents = (currentEvent, allEvents) => {
    const currentStart = parseISO(currentEvent.start || currentEvent.date);
    const currentEnd = currentEvent.end
      ? parseISO(currentEvent.end)
      : addMinutes(currentStart, currentEvent.duration || 0);

    return allEvents.filter((event) => {
      if (event._id === currentEvent._id) return false;

      const eventStart = parseISO(event.start || event.date);
      const eventEnd = event.end
        ? parseISO(event.end)
        : addMinutes(eventStart, event.duration || 0);

      return currentStart < eventEnd && currentEnd > eventStart;
    });
  };

  // Add this component for the enhanced tooltip
  const EnhancedTooltip = ({
    mainEvent,
    overlappingEvents,
    onEventHover,
    onEventLeave,
  }) => {
    const allEvents = [mainEvent, ...overlappingEvents].sort((a, b) => {
      const aStart = parseISO(a.start || a.date);
      const bStart = parseISO(b.start || b.date);
      return aStart - bStart;
    });

    return (
      <div
        className="absolute z-50 bg-white p-3 rounded-lg shadow-lg border border-gray-200 min-w-[300px]"
        style={{
          bottom: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: "8px",
        }}>
        <div className="text-sm font-semibold mb-2">Overlapping Events:</div>
        <div className="space-y-2">
          {allEvents.map((event) => {
            const isAppointment = "client" in event;
            const start = parseISO(event.start || event.date);

            return (
              <div
                key={event._id}
                className="p-2 rounded-md relative group cursor-pointer"
                style={{
                  backgroundColor: isAppointment
                    ? `${event.clinician.calendarColor}20`
                    : "#6B728020",
                  border: isAppointment ? "none" : "1px dashed #6B7280",
                }}
                onMouseEnter={() => onEventHover(event)}
                onMouseLeave={onEventLeave}>
                <div className="flex justify-between items-start">
                  <div>
                    <div
                      className="font-medium"
                      style={{
                        color: isAppointment
                          ? event.clinician.calendarColor
                          : "#374151",
                      }}>
                      {format(start, "h:mm a")}
                      {isAppointment
                        ? ` - ${event.clinician.calendarDisplayName}`
                        : " - Meeting"}
                    </div>
                    <div className="text-sm">
                      {isAppointment
                        ? `${event.client.firstName} ${event.client.lastName}`
                        : event.attendees.map((attendee, index) => (
                            <span key={attendee._id}>
                              {`${attendee.firstName} ${attendee.lastName}`}
                              {index < event.attendees.length - 1 ? ", " : ""}
                            </span>
                          ))}
                    </div>
                    <div className="text-sm text-gray-600">{event.title}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const isUserClinician = clinicians.find(
    (clinician) => clinician._id === user._id
  );

  const [selectedClinicians, setSelectedClinicians] = useState(
    isUserClinician ? [isUserClinician] : []
  );

  const handleClinicianChange = (selectedOptions) => {
    const chosenClinicians = selectedOptions
      .map((option) => option.value)
      .filter(Boolean);

    setSelectedClinicians((prevSelected) => {
      const combined = [
        ...prevSelected.filter(
          (prevClinician) =>
            !chosenClinicians.some(
              (newClinician) => newClinician._id === prevClinician._id
            )
        ),
        ...chosenClinicians,
      ];
      return combined;
    });
  };

  const handleRemoveClinician = (clinicianId) => {
    setSelectedClinicians((prev) =>
      prev.filter((clinician) => clinician._id !== clinicianId)
    );
  };

  return (
    <div className="flex h-full flex-col">
      <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
        <div className="flex items-center">
          <h1 className="text-lg font-semibold text-gray-900">
            <time dateTime={format(startDate, "yyyy-MM")}>
              {format(startDate, "MMMM yyyy")}
            </time>
          </h1>
          <div className="ml-6 flex items-center space-x-2">
            <div className="relative">
              <MultiSelect
                options={clinicians.map((clinician) => ({
                  value: clinician,
                  _id: clinician._id,
                  label: `${clinician.firstName} ${clinician.lastName}`,
                }))}
                selectedValues={selectedClinicians}
                onChange={handleClinicianChange}
                placeholder="Filter clinicians"
                className="w-64"
              />
            </div>
            <div className="flex flex-wrap gap-1">
              {selectedClinicians.map((clinician) => (
                <span
                  key={clinician._id}
                  className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-primary/10 text-primary"
                  style={{
                    backgroundColor: `${clinician.calendarColor}20`,
                    color: clinician.calendarColor,
                  }}>
                  {clinician.firstName} {clinician.lastName}
                  <button
                    type="button"
                    className="ml-1 hover:text-red-500"
                    onClick={() => handleRemoveClinician(clinician._id)}>
                    ×
                  </button>
                </span>
              ))}
              {selectedClinicians.length === 0 && (
                <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-600">
                  All clinicians
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={handlePreviousWeek}
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
              <span className="sr-only">Previous week</span>
              <ChevronLeftIcon className="size-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={handleToday}
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={handleNextWeek}
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
              <span className="sr-only">Next week</span>
              <ChevronRightIcon className="size-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </header>
      <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black/5 sm:pr-8">
            <div className="grid grid-cols-7 text-sm/6 text-gray-500 sm:hidden">
              {weekDays.map((day) => (
                <button
                  key={day.shortDay}
                  type="button"
                  className="flex flex-col items-center pb-3 pt-2">
                  {day.shortDay}{" "}
                  <span
                    className={`mt-1 flex size-8 items-center justify-center font-semibold
                      ${
                        day.isToday
                          ? "rounded-full bg-indigo-600 text-white"
                          : "text-gray-900"
                      }`}>
                    {day.date}
                  </span>
                </button>
              ))}
            </div>

            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm/6 text-gray-500 sm:grid">
              <div className="col-end-1 w-14" />
              {weekDays.map((day) => (
                <div
                  key={day.fullDay}
                  className="flex items-center justify-center py-3">
                  <span className="flex items-baseline">
                    {day.fullDay}{" "}
                    <span
                      className={`ml-1.5 flex items-center justify-center font-semibold
                        ${
                          day.isToday
                            ? "size-8 rounded-full bg-indigo-600 text-white"
                            : "text-gray-900"
                        }`}>
                      {day.date}
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}>
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    12AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    1AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    2AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    3AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    4AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    5AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    9PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    10PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
                    11PM
                  </div>
                </div>
                <div />
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="mt-4 col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}>
                {/* Render appointments */}
                {sevenDayAppointments
                  .filter(
                    (appointment) =>
                      selectedClinicians.length === 0 ||
                      selectedClinicians.some(
                        (c) => c._id === appointment.clinician._id
                      )
                  )
                  .map((appointment) => {
                    const { startRow, span } = calculateAppointmentGridPosition(
                      appointment.start,
                      appointment.end
                    );
                    const localStart = toDate(parseISO(appointment.start), {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                    const dayOfWeek = localStart.getDay() + 1;

                    const handleMouseEnter = (e) => {
                      const overlappingEvents = findOverlappingEvents(
                        appointment,
                        [...sevenDayAppointments, ...sevenDayMeetings]
                      );

                      setHoveredItem({
                        type: "appointment",
                        data: appointment,
                        overlappingEvents: overlappingEvents,
                      });
                    };

                    const handleMouseLeave = () => {
                      setHoveredItem(null);
                    };

                    return (
                      <li
                        key={appointment._id}
                        className="relative mt-px flex"
                        style={{
                          gridRow: `${startRow} / span ${span}`,
                          gridColumnStart: dayOfWeek,
                          position: "relative",
                        }}>
                        <span
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg px-1.5 py-1 text-xs/5 cursor-pointer"
                          style={{
                            backgroundColor: appointment.clinician.calendarColor
                              ? `${appointment.clinician.calendarColor}20`
                              : "#3b82f620",
                            zIndex: 1,
                          }}>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            <time dateTime={appointment.start}>
                              {format(localStart, "h:mm a")}
                            </time>
                            <strong>
                              {" - "}
                              {appointment.clinician.calendarDisplayName}
                            </strong>
                          </p>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            {appointment.title}
                          </p>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            {appointment.client.firstName}{" "}
                            {appointment.client.lastName}
                          </p>
                        </span>
                        {hoveredItem &&
                          hoveredItem.data._id === appointment._id &&
                          (hoveredItem.overlappingEvents.length > 0 ? (
                            <EnhancedTooltip
                              mainEvent={hoveredItem.data}
                              overlappingEvents={hoveredItem.overlappingEvents}
                              onEventHover={(event) => {
                                setHoveredItem({
                                  type:
                                    "client" in event
                                      ? "appointment"
                                      : "meeting",
                                  data: event,
                                  overlappingEvents:
                                    hoveredItem.overlappingEvents,
                                });
                              }}
                              onEventLeave={() => {
                                setHoveredItem({
                                  type: hoveredItem.type,
                                  data: hoveredItem.data,
                                  overlappingEvents:
                                    hoveredItem.overlappingEvents,
                                });
                              }}
                            />
                          ) : (
                            <div
                              className="absolute z-50 bg-white p-1.5 rounded-lg shadow-lg border border-gray-200 text-sm whitespace-nowrap"
                              style={{
                                bottom: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                              }}>
                              <p className="font-semibold">
                                Clinician: {appointment.clinician.firstName}{" "}
                                {appointment.clinician.lastName}
                              </p>
                              <p>
                                Client: {appointment.client.firstName}{" "}
                                {appointment.client.lastName}
                              </p>
                              <p>
                                Status:{" "}
                                {appointment.history.length > 0
                                  ? appointment.history[
                                      appointment.history.length - 1
                                    ].status
                                  : "No status"}
                              </p>
                            </div>
                          ))}
                      </li>
                    );
                  })}

                {/* Render meetings */}
                {sevenDayMeetings
                  .filter(
                    (meeting) =>
                      selectedClinicians.length === 0 ||
                      meeting.attendees.some((attendee) =>
                        selectedClinicians.some((c) => c._id === attendee._id)
                      )
                  )
                  .map((meeting) => {
                    const { startRow, span } = calculateMeetingGridPosition(
                      meeting.date,
                      meeting.startTime,
                      meeting.duration
                    );
                    const localStart = toDate(parseISO(meeting.date), {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });

                    // Create tooltip content for meetings
                    const tooltipContent = `
                      Attendees: ${meeting.attendees
                        .map((a) => `${a.firstName} ${a.lastName}`)
                        .join(", ")}
                    `.trim();

                    // Get the day of week (1-7) for the meeting
                    const dayOfWeek = localStart.getDay() + 1; // Sunday will be column 1, Saturday will be column 7

                    const handleMouseEnter = (e) => {
                      const overlappingEvents = findOverlappingEvents(meeting, [
                        ...sevenDayAppointments,
                        ...sevenDayMeetings,
                      ]);

                      setHoveredItem({
                        type: "meeting",
                        data: meeting,
                        overlappingEvents: overlappingEvents,
                      });
                    };

                    const handleMouseLeave = () => {
                      setHoveredItem(null);
                    };

                    return (
                      <li
                        key={meeting._id}
                        className="relative mt-px flex"
                        style={{
                          gridRow: `${startRow} / span ${span}`,
                          gridColumnStart: dayOfWeek,
                          position: "relative",
                        }}>
                        <span
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          title={tooltipContent}
                          className="mt-0.5 group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-1.5 text-xs/5 cursor-pointer"
                          style={{
                            backgroundColor: "#6B728020",
                            border: "1px dashed #6B7280",
                            zIndex: 1,
                          }}>
                          <p className="font-semibold text-gray-700">
                            <time dateTime={meeting.date}>
                              {format(localStart, "h:mm a")}
                            </time>
                            {" - Meeting"}
                          </p>
                          <p className="text-gray-600">{meeting.title}</p>
                          <p className="text-gray-600">
                            Attendees:{" "}
                            {meeting.attendees
                              .map((a) => `${a.firstName} ${a.lastName}`)
                              .join(", ")}
                          </p>
                          {meeting.description && (
                            <p className="text-gray-600 italic">
                              {meeting.description}
                            </p>
                          )}
                        </span>
                        {hoveredItem &&
                          hoveredItem.data._id === meeting._id &&
                          (hoveredItem.overlappingEvents.length > 0 ? (
                            <EnhancedTooltip
                              mainEvent={hoveredItem.data}
                              overlappingEvents={hoveredItem.overlappingEvents}
                              onEventHover={(event) => {
                                setHoveredItem({
                                  type:
                                    "client" in event
                                      ? "appointment"
                                      : "meeting",
                                  data: event,
                                  overlappingEvents:
                                    hoveredItem.overlappingEvents,
                                });
                              }}
                              onEventLeave={() => {
                                setHoveredItem({
                                  type: hoveredItem.type,
                                  data: hoveredItem.data,
                                  overlappingEvents:
                                    hoveredItem.overlappingEvents,
                                });
                              }}
                            />
                          ) : (
                            <div
                              className="absolute z-50 bg-white p-1.5 rounded-lg shadow-lg border border-gray-200 text-sm whitespace-nowrap"
                              style={{
                                bottom: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                              }}>
                              <p className="font-semibold">Attendees:</p>
                              {hoveredItem.data.attendees.map(
                                (attendee, index) => (
                                  <p key={index}>
                                    {attendee.firstName} {attendee.lastName}
                                  </p>
                                )
                              )}
                            </div>
                          ))}
                      </li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
