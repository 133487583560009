import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import BankingInstitutionNumberTable from "../../../utils/BankingInstitutionNumberTable";
import { ALL_ROLES } from "../../../constants/roles";

const UserHrInformation = ({ user = {}, onComplete }) => {
  const dispatch = useDispatch();
  const [saveStatus, setSaveStatus] = useState('initial');

  const userUser = user.user;

  console.log("recived user (actually.. response) in hrinformation", user);
  console.log("received user.user in userhrinformation", userUser);
  console.log("received userid from adduser", user.userId);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      employmentStatus: user.employmentStatus || "",
      activeStatus: user.userStatus || "active",
      hireDate: user.startDate
        ? new Date(user.startDate).toISOString().split("T")[0]
        : "",
      terminationDate: user.terminationDate
        ? new Date(user.terminationDate).toISOString().split("T")[0]
        : "",
      jobTitle: user.jobTitle || "",
      department: user.department || "",
      role: Array.isArray(user.role)
        ? user.role.filter(Boolean)
        : [user.role].filter(Boolean),
      bankName: user.bankName || "",
      institutionNumber: user.institutionNumber || "",
      accountNumber: user.accountNumber || "",
      branchNumber: user.branchNumber || "",
      socialInsuranceNumber: user.socialInsuranceNumber || "",
      defaultPayPercentage: user.defaultPayPercentage || 45,
      qualifications: user.qualifications || "",
      invoiceDescriptor: user.invoiceDescriptor || "",
      calendarColor: user.calendarColor || "",
      calendarDisplayName: user.calendarDisplayName || "",
      specialization: Array.isArray(user.specialization)
        ? user.specialization.filter(Boolean) // Filter out null or undefined values here
        : [user.specialization].filter(Boolean),
    },
    validationSchema: Yup.object({
      employmentStatus: Yup.string().required("Required"),
      activeStatus: Yup.string().required("Required"),
      hireDate: Yup.date().required("Required"),
      terminationDate: Yup.date().nullable(),
      jobTitle: Yup.string().required("Required"),
      department: Yup.string().required("Required"),
      role: Yup.array().min(1, "At least one role must be selected"),
      bankName: Yup.string(),
      institutionNumber: Yup.string(),
      accountNumber: Yup.string(),
      branchNumber: Yup.string(),
      socialInsuranceNumber: Yup.string(),
      defaultPayPercentage: Yup.string(),
    }),
    onSubmit: (values) => {
      setSaveStatus('saving');

      const getChangedFields = (values, original) => {
        const changedFields = {};

        for (const key in values) {
          if (
            JSON.stringify(values[key]) !== JSON.stringify(original[key]) &&
            values[key] !== ""
          ) {
            changedFields[key] = values[key];
          }
        }

        return changedFields;
      };

      const userData = getChangedFields(values, {
        ...user,
        startDate: user.startDate,
        userStatus: user.userStatus,
      });

      // Rename specific fields to match the backend schema
      if (userData.activeStatus) userData.userStatus = userData.activeStatus;
      if (userData.hireDate) userData.startDate = userData.hireDate;

      // Filter out any null or undefined values from specialization
      if (userData.specialization) {
        userData.specialization = userData.specialization.filter(Boolean);
      }

      // Add the `__t` field if `role` includes 'clinician'
      if (userData.role && userData.role.includes("clinician")) {
        userData.__t = "clinician";
      }

      // Remove temporary fields
      delete userData.activeStatus;
      delete userData.hireDate;

      console.log("Modifying user with data:", userData);

      dispatch(modifyUser({ userId: user._id || user.userId, userData }))
        .then((response) => {
          console.log("User modified successfully:", response);
          setSaveStatus('saved');
          onComplete(response.payload);
          
          // Reset the button text after 2 seconds
          setTimeout(() => {
            setSaveStatus('initial');
          }, 2000);
        })
        .catch((error) => {
          console.error("Error modifying user:", error);
          setSaveStatus('error');
        });
    },
  });

  const specializations = ["SLP", "CDA"];

  const handleBankChange = (event) => {
    const selectedBank = event.target.value;
    const bankingTable = BankingInstitutionNumberTable();
    const selectedInstitution =
      bankingTable.props.children.props.children[1].props.children.find(
        (bank) => bank.props.children[0].props.children === selectedBank
      );
    formik.setFieldValue("bankName", selectedBank);
    formik.setFieldValue(
      "institutionNumber",
      selectedInstitution
        ? selectedInstitution.props.children[1].props.children
        : ""
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="employmentStatus"
            className="block text-sm font-medium text-gray-700">
            Employment Status
          </label>
          <div className="mt-1">
            <select
              id="employmentStatus"
              name="employmentStatus"
              {...formik.getFieldProps("employmentStatus")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="">Select status</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contract">Contract</option>
              <option value="Temporary">Temporary</option>
            </select>
          </div>
          {formik.touched.employmentStatus && formik.errors.employmentStatus ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.employmentStatus}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="activeStatus"
            className="block text-sm font-medium text-gray-700">
            Active Status
          </label>
          <div className="mt-1">
            <select
              id="activeStatus"
              name="activeStatus"
              {...formik.getFieldProps("activeStatus")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="active">Active</option>
              <option value="inactive">
                Inactive (User will not be able to login)
              </option>
            </select>
          </div>
          {formik.touched.activeStatus && formik.errors.activeStatus ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.activeStatus}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="hireDate"
            className="block text-sm font-medium text-gray-700">
            Hire Date
          </label>
          <div className="mt-1">
            <input
              type="date"
              name="hireDate"
              id="hireDate"
              {...formik.getFieldProps("hireDate")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.hireDate && formik.errors.hireDate ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.hireDate}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="terminationDate"
            className="block text-sm font-medium text-gray-700">
            Termination Date
          </label>
          <div className="mt-1">
            <input
              type="date"
              name="terminationDate"
              id="terminationDate"
              {...formik.getFieldProps("terminationDate")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.terminationDate && formik.errors.terminationDate ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.terminationDate}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="jobTitle"
            className="block text-sm font-medium text-gray-700">
            Job Title
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="jobTitle"
              id="jobTitle"
              {...formik.getFieldProps("jobTitle")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.jobTitle && formik.errors.jobTitle ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.jobTitle}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="department"
            className="block text-sm font-medium text-gray-700">
            Department
          </label>
          <div className="mt-1">
            <select
              id="department"
              name="department"
              {...formik.getFieldProps("department")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="">Select department</option>
              <option value="Speech">Speech</option>
              <option value="Audiology">Audiology</option>
              <option value="Corporate">Corporate</option>
            </select>
          </div>
          {formik.touched.department && formik.errors.department ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.department}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="qualifications"
            className="block text-sm font-medium text-gray-700">
            Qualifications
          </label>
          <div className="mt-1">
            <textarea
              id="qualifications"
              name="qualifications"
              rows="3"
              {...formik.getFieldProps("qualifications")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.qualifications && formik.errors.qualifications ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.qualifications}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="invoiceDescriptor"
            className="block text-sm font-medium text-gray-700">
            Invoice Descriptor
          </label>
          <div className="mt-1">
            <textarea
              id="invoiceDescriptor"
              name="invoiceDescriptor"
              rows="1"
              {...formik.getFieldProps("invoiceDescriptor")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.invoiceDescriptor &&
          formik.errors.invoiceDescriptor ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.invoiceDescriptor}
            </div>
          ) : null}
        </div>

        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-6"> */}
        <div className="sm:col-span-3">
          <label
            htmlFor="calendarColor"
            className="block text-sm font-medium text-gray-700">
            Calendar Color
          </label>
          <div className="mt-1 flex items-center">
            <input
              type="color"
              id="calendarColor"
              name="calendarColor"
              {...formik.getFieldProps("calendarColor")}
              className="h-10 w-20 p-1 rounded-l-md border-gray-300"
            />
            <input
              type="text"
              value={formik.values.calendarColor}
              onChange={(e) => {
                // Ensure value starts with #
                let value = e.target.value;
                if (!value.startsWith("#")) value = "#" + value;
                formik.setFieldValue("calendarColor", value);
              }}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full sm:text-sm border-gray-300 rounded-r-md"
              placeholder="#000000"
            />
          </div>
          {formik.touched.calendarColor && formik.errors.calendarColor ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.calendarColor}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="calendarDisplayName"
            className="block text-sm font-medium text-gray-700">
            Calendar Display Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="calendarDisplayName"
              name="calendarDisplayName"
              {...formik.getFieldProps("calendarDisplayName")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.calendarDisplayName &&
          formik.errors.calendarDisplayName ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.calendarDisplayName}
            </div>
          ) : null}
        </div>
        {/* </div> */}

        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Role and Specialization
          </label>
          <div className="mt-2 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Role
              </label>
              <div className="space-y-2">
                {ALL_ROLES.map((role) => (
                  <div key={role} className="flex items-center">
                    <input
                      id={`role-${role}`}
                      name="role"
                      type="checkbox"
                      value={role}
                      checked={formik.values.role.includes(role)}
                      onChange={formik.handleChange}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={`role-${role}`}
                      className="ml-3 text-sm text-gray-700">
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
              {formik.touched.role && formik.errors.role ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.role}
                </div>
              ) : null}
            </div>
            {formik.values.role.includes("clinician") && (
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Specialization
                </label>
                <div className="space-y-2">
                  {specializations.map((spec) => (
                    <div key={spec} className="flex items-center">
                      <input
                        id={`specialization-${spec}`}
                        name="specialization"
                        type="checkbox"
                        value={spec}
                        checked={formik.values.specialization.includes(spec)}
                        onChange={formik.handleChange}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label
                        htmlFor={`specialization-${spec}`}
                        className="ml-3 text-sm text-gray-700">
                        {spec}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.touched.specialization &&
                formik.errors.specialization ? (
                  <div className="text-red-500 text-sm mt-1">
                    {formik.errors.specialization}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <div className="sm:col-span-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Financial Information
          </h3>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="bankName"
                className="block text-sm font-medium text-gray-700">
                Bank Name
              </label>
              <div className="mt-1">
                <select
                  name="bankName"
                  id="bankName"
                  onChange={handleBankChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankName}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option value="">Select a bank</option>
                  {BankingInstitutionNumberTable().props.children.props.children[1].props.children.map(
                    (bank) => (
                      <option
                        key={bank.key}
                        value={bank.props.children[0].props.children}>
                        {bank.props.children[0].props.children}
                      </option>
                    )
                  )}
                </select>
              </div>
              {formik.touched.bankName && formik.errors.bankName ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.bankName}
                </div>
              ) : null}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="institutionNumber"
                className="block text-sm font-medium text-gray-700">
                Institution Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="institutionNumber"
                  id="institutionNumber"
                  {...formik.getFieldProps("institutionNumber")}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  readOnly
                />
              </div>
              {formik.touched.institutionNumber &&
              formik.errors.institutionNumber ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.institutionNumber}
                </div>
              ) : null}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="accountNumber"
                className="block text-sm font-medium text-gray-700">
                Account Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="accountNumber"
                  id="accountNumber"
                  {...formik.getFieldProps("accountNumber")}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {formik.touched.accountNumber && formik.errors.accountNumber ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.accountNumber}
                </div>
              ) : null}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="branchNumber"
                className="block text-sm font-medium text-gray-700">
                Branch Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="branchNumber"
                  id="branchNumber"
                  {...formik.getFieldProps("branchNumber")}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {formik.touched.branchNumber && formik.errors.branchNumber ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.branchNumber}
                </div>
              ) : null}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="socialInsuranceNumber"
                className="block text-sm font-medium text-gray-700">
                Social Insurance Number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="socialInsuranceNumber"
                  id="socialInsuranceNumber"
                  {...formik.getFieldProps("socialInsuranceNumber")}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {formik.touched.socialInsuranceNumber &&
              formik.errors.socialInsuranceNumber ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.socialInsuranceNumber}
                </div>
              ) : null}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="defaultPayPercentage"
                className="block text-sm font-medium text-gray-700">
                Default Pay Percentage
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="defaultPayPercentage"
                  id="defaultPayPercentage"
                  {...formik.getFieldProps("defaultPayPercentage")}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              {formik.touched.defaultPayPercentage &&
              formik.errors.defaultPayPercentage ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.defaultPayPercentage}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={saveStatus === 'saving'}
          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
            ${saveStatus === 'saving' ? 'bg-indigo-400' : 
              saveStatus === 'saved' ? 'bg-green-600' :
              saveStatus === 'error' ? 'bg-red-600' : 'bg-indigo-600 hover:bg-indigo-700'} 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
          {saveStatus === 'saving' ? 'Saving...' :
            saveStatus === 'saved' ? 'HR Information Saved!' :
            saveStatus === 'error' ? 'Error Saving' : 'Save HR Information'}
        </button>
      </div>
    </form>
  );
};

export default UserHrInformation;
