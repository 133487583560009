import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "../store/thunks/paymentsThunk";
import CurrencyInput from "react-currency-input-field";

const InvoicePaymentModal = ({ payment, onClose }) => {
  const invoices = useSelector((state) => state.invoices.appointmentInvoices);
  const payments = useSelector((state) => state.payments.appointmentPayments);
  const selectedClinic = useSelector((state) => state.clinics.selectedClinic);
  const paymentMethods = selectedClinic?.paymentMethods || [];

  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [paymentAmounts, setPaymentAmounts] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(
    payment?.paymentMethod || ""
  );
  const [paymentDate, setPaymentDate] = useState(
    new Date(payment?.paymentDate || new Date()).toISOString().split("T")[0]
  );
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (payment) {
      // When `payment` is passed, initialize with its details
      const invoice = payment.invoiceId;
      if (invoice) {
        setSelectedInvoices({ [invoice._id]: true });
        setPaymentAmounts({ [invoice._id]: invoice.amount.toFixed(2) });
      }
    } else {
      // Original logic when `payment` is not passed
      const initialSelection = {};
      const initialAmounts = {};
      invoices.forEach((invoice) => {
        const hasValidPayment = payments?.some(
          (payment) => payment.invoiceId._id === invoice._id && !payment.voided
        );
        initialSelection[invoice._id] = false;
        initialAmounts[invoice._id] = invoice.amount
          ? invoice.amount.toFixed(2)
          : "0.00"; // Default to "0.00" if undefined
      });
      setSelectedInvoices(initialSelection);
      setPaymentAmounts(initialAmounts);
    }
  }, [payment, invoices, payments]);

  const handleInvoiceSelection = (invoiceId) => {
    if (payment) return; // Disable selection when `payment` is passed
    const hasValidPayment = payments?.some(
      (payment) => payment.invoiceId._id === invoiceId && !payment.voided
    );
    if (!hasValidPayment) {
      setSelectedInvoices((prev) => ({
        ...prev,
        [invoiceId]: !prev[invoiceId],
      }));
    }
  };

  const handlePaymentAmountChange = (invoiceId, value) => {
    setPaymentAmounts((prev) => ({
      ...prev,
      [invoiceId]: value,
    }));
  };

  const totalPaymentAmount = payment
    ? parseFloat(paymentAmounts[payment.invoiceId?._id] || 0)
    : invoices
        .filter((invoice) => selectedInvoices[invoice._id])
        .reduce(
          (total, invoice) =>
            total + parseFloat(paymentAmounts[invoice._id] || 0),
          0
        );

  const handleSavePayment = async () => {
    if (!paymentMethod || totalPaymentAmount <= 0) {
      setError("Please select unpaid invoices and enter a payment method.");
      return;
    }

    try {
      if (payment) {
        // Logic for saving a passed payment
        const invoice = payment.invoiceId;
        if (invoice) {
          await dispatch(
            createPayment({
              invoiceId: invoice._id,
              paymentMethod,
              paymentAmount: parseFloat(paymentAmounts[invoice._id]).toFixed(2),
              paymentDate: new Date(paymentDate).toISOString(),
            })
          ).unwrap();
          onClose();
        }
      } else {
        // Original logic for saving payments
        let successCount = 0;
        for (const invoice of invoices) {
          if (selectedInvoices[invoice._id]) {
            try {
              await dispatch(
                createPayment({
                  invoiceId: invoice._id,
                  paymentMethod,
                  paymentAmount: parseFloat(
                    paymentAmounts[invoice._id]
                  ).toFixed(2),
                  paymentDate: new Date(paymentDate).toISOString(),
                })
              ).unwrap();
              successCount++;
            } catch (err) {
              console.error(
                `Failed to process payment for invoice ${invoice._id}:`,
                err
              );
            }
          }
        }

        if (successCount === 0) {
          setError("Failed to process any payments. Please try again.");
        } else {
          onClose();
        }
      }
    } catch (error) {
      console.error("Error processing payments:", error);
      setError("Failed to process payments. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg max-w-5xl w-full p-6 relative">
        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          Invoice Payment
        </h2>

        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-900 mb-2">
            {payment ? "Invoice Details:" : "Select Invoices to Pay:"}
          </h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {!payment && (
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                )}
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice ID
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Service Date
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client Name
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Amount
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Amount
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {(payment ? [payment.invoiceId] : invoices).map((invoice) => {
                const hasValidPayment = payments?.some(
                  (pay) => pay.invoiceId?._id === invoice._id && !pay.voided
                );
                return (
                  <tr
                    key={invoice._id}
                    className={hasValidPayment ? "bg-gray-50" : ""}>
                    {!payment && (
                      <td className="px-3 py-2 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={selectedInvoices[invoice._id]}
                          onChange={() => handleInvoiceSelection(invoice._id)}
                          disabled={hasValidPayment}
                          className="mr-2"
                        />
                      </td>
                    )}
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {invoice.invoiceId}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {new Date(invoice.serviceDate).toLocaleDateString()}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {invoice.client.firstName} {invoice.client.lastName}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      ${invoice.amount.toFixed(2)}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      <CurrencyInput
                        id={`payment-amount-${invoice._id}`}
                        name={`payment-amount-${invoice._id}`}
                        prefix="$"
                        placeholder="Enter amount"
                        value={paymentAmounts[invoice._id] || "0.00"}
                        onValueChange={(value) =>
                          handlePaymentAmountChange(invoice._id, value)
                        }
                        decimalsLimit={2}
                        decimalSeparator="."
                        intlConfig={{
                          locale: "en-US",
                          currency: "USD",
                        }}
                        disabled={
                          (!payment && !selectedInvoices[invoice._id]) ||
                          hasValidPayment
                        }
                        className="w-24 rounded-md border-gray-300 shadow-sm"
                      />
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {hasValidPayment ? (
                        <span className="text-green-600">Paid</span>
                      ) : (
                        <span className="text-red-600">Unpaid</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-900">
            Total Payment Amount
          </label>
          <p className="text-lg font-semibold text-gray-900">
            ${totalPaymentAmount.toFixed(2)}
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-900">
              Payment Method
            </label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className="block w-full mt-2 rounded-md border-gray-300 shadow-sm">
              <option value="">Select Method</option>
              {paymentMethods.map((method) => (
                <option key={method.value} value={method.value}>
                  {method.englishDescription}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">
              Payment Date
            </label>
            <input
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              className="block w-full mt-2 rounded-md border-gray-300 shadow-sm"
            />
          </div>
        </div>

        {error && <div className="text-sm text-red-600 mb-4">{error}</div>}

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={onClose}
            className="text-sm font-semibold text-gray-900">
            Cancel
          </button>
          <button
            onClick={handleSavePayment}
            className="rounded-md px-3 py-2 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 flex items-center"
            disabled={totalPaymentAmount <= 0 || !paymentMethod}>
            Save Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;
