import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../store/thunks/authThunk"; // You'll need to create this thunk
import { Link } from "react-router-dom";
import logo from "../assets/your_logo.png";

const PasswordReset = () => {
    const dispatch = useDispatch();
    const { error, message } = useSelector((state) => state.auth);

    const [token, setToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);

    const checkTokenValidity = useCallback(async (token) => {
        try {
            const result = await dispatch(checkTokenValidity(token));
            if (!result.payload.isValid) {
                setTokenExpired(true);
            }
        } catch (error) {
            console.error('Error checking token validity:', error);
            setTokenExpired(true);
        }
    }, [dispatch]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
            checkTokenValidity(tokenFromUrl);
        }
    }, [checkTokenValidity]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        dispatch(resetPassword({ token, newPassword }));
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="h-36 w-auto"
                    src={logo}
                    alt="Your Business Name Here"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {tokenExpired ? (
                    <p className="text-red-600">Password reset link has expired. Please contact an administrator for a new link.</p>
                ) : (
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label
                                htmlFor="newPassword"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                New Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="confirmPassword"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Confirm Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    autoComplete="confirm-password"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <button type="submit">Reset Password</button>
                    </form>
                )}

                {error && (
                    <p className="mt-2 text-center text-sm text-red-600">
                        {error}
                    </p>
                )}
                {message && (
                    <p className="mt-2 text-center text-sm text-green-600">
                        {message}
                    </p>
                )}

                <p className="mt-10 text-center text-sm text-gray-500">
                    Remember your password?{" "}
                    <Link
                        to="/login"
                        className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                    >
                        Log in here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default PasswordReset;
